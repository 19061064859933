import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import PropertyList from './components/PropertyList';
import Header from './components/Header';
import ContactForm from './components/ContactForm';
import HowItWorks from './components/HowItWorks';
import Tabs from './components/Tabs';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App scroll-smooth">
        <NavBar/>
        <Header/>
        {/* <PropertyList/> */}
        <HowItWorks/>
        <Tabs/>
        <ContactForm/>
        <Footer/>
    </div>
  );
}

export default App;
