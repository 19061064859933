import React, { useEffect, useRef, useState } from 'react';
import { FaInfoCircle, FaStar, FaHandsHelping, FaEye } from 'react-icons/fa';

const tabData = [
  {
    title: 'Quiénes somos',
    icon: <FaInfoCircle size={32} />,
    content: (
      <>
        Prop2Sell redefine la venta de propiedades con una experiencia completamente <strong>transparente</strong> y <strong>controlada</strong> por ti.
        Nuestra plataforma está diseñada para aquellos propietarios que desean estar totalmente involucrados en cada paso de la venta, <strong>sin sorpresas</strong> de último minuto ni costos ocultos. 
        Además, te proporcionamos <strong>herramientas de seguimiento</strong> para garantizar que estés al tanto de cada movimiento en el proceso.
      </>
    ),
  },
  {
    title: 'Lo que nos hace diferentes',
    icon: <FaStar size={32} />,
    content: (
      <>
        Con Prop2Sell, mantienes el control absoluto sobre la venta. Nuestra plataforma te permite estar al mando en cada paso del proceso, garantizando que <strong>no haya sorpresas</strong> de último minuto.
        Nos destacamos por ofrecer una experiencia <strong>personalizada</strong> que se adapta a las necesidades de cada propietario, con el <strong>apoyo continuo</strong> de nuestros expertos en el sector inmobiliario.
      </>
    ),
  },
  {
    title: 'Participación Activa',
    icon: <FaHandsHelping size={32} />,
    content: (
      <>
        Si prefieres estar proactivamente involucrado en la venta y conocer cada detalle, esta es la herramienta ideal para ti. <strong>Prop2Sell</strong> te presenta al mejor comprador y tú presentas la vivienda. 
        No solo te facilitamos el contacto con compradores interesados, sino que también te proporcionamos <strong>estrategias</strong> para maximizar el valor de tu propiedad y cerrar la venta en el <strong>menor tiempo posible</strong>.
      </>
    ),
  },
  {
    title: 'Transparencia Total',
    icon: <FaEye size={32} />,
    content: (
      <>
        Entendemos que el sector inmobiliario ha sido criticado por su falta de claridad. En <strong>Prop2Sell</strong>, combatimos esa opacidad con un enfoque completamente <strong>transparente</strong>.
        Te mostramos cada detalle del proceso, eliminando sorpresas y garantizando una venta sin complicaciones. Todo lo que necesitas saber estará a tu disposición, asegurando una experiencia de venta <strong>segura y confiable</strong>.
      </>
    ),
  },
];

const Tabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const sectionRef = useRef(null);

  // Use IntersectionObserver to detect when section is visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div id="quienes-somos" ref={sectionRef} className="w-full max-w-3xl mx-auto my-8 px-4">
      <h2 className={`text-3xl font-bold text-center mb-6 text-warmBrown transition-all duration-700 ease-in-out ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
        Quiénes Somos
      </h2>

      {/* Vertical layout for mobile */}
      <div className="flex flex-col sm:flex-row justify-start gap-4 mb-4">
        <div className="flex flex-col items-center gap-4">
          {tabData.map((tab, index) => (
            <button
              key={index}
              className={`flex flex-col items-center py-2 px-4 rounded-t-lg font-medium shadow-lg transition-transform duration-300 ease-in-out hover:scale-105 ${
                index === activeTab
                  ? 'bg-warmBrown text-white font-semibold border-b-4 border-golden shadow-md bg-gradient-to-r from-warmBrown to-golden'
                  : 'bg-cream text-darkGray hover:bg-golden'
              }`}
              onClick={() => setActiveTab(index)}
            >
              {tab.icon}
              {/* Show title only when active */}
              {index === activeTab && (
                <span className="mt-2">{tab.title}</span>
              )}
            </button>
          ))}
        </div>

        <div
          className={`p-6 bg-white rounded-lg shadow-lg text-center mt-4 min-h-[200px] transition-opacity duration-300 ${
            isVisible ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <p className="text-gray-800">{tabData[activeTab].content}</p>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
