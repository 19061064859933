import React, { useState } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Navbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <nav className="bg-warmBrown p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <h1 className="text-white text-2xl font-bold">Prop2sell</h1>

        {/* Botón para abrir el menú en móvil */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white text-3xl focus:outline-none">
            {menuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>

        {/* Menú visible en pantallas medianas y grandes */}
        <ul className="hidden md:flex space-x-4 text-white">
          <li><span onClick={() => handleScroll('home')} className="hover:text-golden cursor-pointer">Home</span></li>
          <li><span onClick={() => handleScroll('como-funciona')} className="hover:text-golden cursor-pointer">Cómo Funciona</span></li>
          <li><span onClick={() => handleScroll('quienes-somos')} className="hover:text-golden cursor-pointer">Quiénes Somos</span></li>
          <li><span onClick={() => handleScroll('contacto')} className="hover:text-golden cursor-pointer">Contacto</span></li>
        </ul>

        {/* Menú desplegable en pantallas pequeñas */}
        <div
          className={`${
            menuOpen ? 'block' : 'hidden'
          } absolute top-16 left-0 w-full bg-warmBrown z-50 md:hidden`}
        >
           <ul className="flex flex-col items-center space-y-4 py-4">
            <li><span onClick={() => { handleScroll('home'); toggleMenu(); }} className="text-white hover:text-golden cursor-pointer">Home</span></li>
            <li><span onClick={() => { handleScroll('como-funciona'); toggleMenu(); }} className="text-white hover:text-golden cursor-pointer">Cómo Funciona</span></li>
            <li><span onClick={() => { handleScroll('quienes-somos'); toggleMenu(); }} className="text-white hover:text-golden cursor-pointer">Quiénes Somos</span></li>
            <li><span onClick={() => { handleScroll('contacto'); toggleMenu(); }} className="text-white hover:text-golden cursor-pointer">Contacto</span></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
