import React, { useEffect, useState } from 'react';

const HowItWorks: React.FC = () => {
  const steps = [
    {
      title: '1. Publicamos tu Propiedad',
      description: 'Publicamos tu propiedad con fotos de calidad y una descripción atractiva.',
      img: '/iconos/captar.png'  // Ruta desde la carpeta public
    },
    {
      title: '2. Tú lo fácil, nosotros lo duro',
      description: 'Deja en nuestras manos la legalidad y la venta; tú lo enseñas.',
      img: '/iconos/legal.png'
    },
    {
      title: '3. Cerramos la venta',
      description: 'Nosotros negociamos y cerramos la venta al mejor precio.',
      img: '/iconos/venta.png'
    }
  ];

  const [showAnimation, setShowAnimation] = useState(false);

  // Este efecto escucha el scroll para activar la animación al llegar a la sección
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('como-funciona');
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (sectionTop < windowHeight - 100) {
          setShowAnimation(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section id="como-funciona" className="bg-gradient-to-b from-cream to-white text-center py-16">
      <h2 className="text-3xl font-bold mb-6 text-warmBrown">¿Cómo Funciona?</h2>

      <div className="flex flex-col md:flex-row justify-around items-center gap-6">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`bg-white shadow-lg rounded-lg p-6 w-64 h-96 flex flex-col justify-between items-center transform transition duration-300 hover:scale-105 section-box 
            ${showAnimation ? 'animate-fadeIn delay-' + index * 200 : 'opacity-0'}`} // Aplica la animación de fadeIn con un pequeño delay
          >
            <div className="mb-4 flex items-center justify-center h-32 w-32">
              <img src={step.img} alt={step.title} className="h-full w-full object-contain" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-center">{step.title}</h3>
            <p className="text-gray-600 text-center">{step.description}</p>
          </div>
        ))}
      </div>

      <button
        onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })}
        className="bg-warmBrown text-white py-3 px-6 rounded-lg mt-8 hover:bg-brown-600 transition-all duration-300 transform hover:scale-105 button-cta"
      >
        Contáctanos
      </button>
    </section>
  );
};

export default HowItWorks;