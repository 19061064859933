import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-b from-cream to-warmBrown text-white py-8 ">
      <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
        <div className="text-center md:text-left mb-4 md:mb-0">
          <p>© 2024 Prop2Sell. Todos los derechos reservados.</p>
        </div>
        <div className="text-center">
          <a href="/terms" className="hover:underline mr-4">Términos y Condiciones</a>
          <a href="/privacy" className="hover:underline">Política de Privacidad</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
