import React, { useState, useRef } from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const form = useRef<HTMLFormElement | null>(null); // Usamos el useRef para el formulario

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (form.current) {
      emailjs.sendForm(
        'service_es4m7d6', // Aquí va el Service ID
        'template_krr6kix', // Reemplaza con el Template ID que configuraste
        form.current,
        '7w38B5bl5oQYNS9Nb' // Reemplaza con tu User ID de EmailJS
      )
      .then((result) => {
        console.log('Email enviado:', result.text);
        alert("Mensaje enviado con éxito");
      })
      .catch((error) => {
        console.log('Error al enviar el email:', error.text);
        alert("Hubo un error al enviar el mensaje. Inténtalo de nuevo.");
      });
    }
  };

  return (
    <div id='contacto' className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-cream px-4 py-12">
      <h2 className='text-4xl font-bold mb-10 text-warmBrown text-center'>Contacto</h2>
      <div className="flex flex-col md:flex-row items-center justify-between max-w-6xl w-full bg-white shadow-2xl rounded-3xl p-8 border border-gray-200">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-12 text-center md:text-left">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Contáctanos</h2>
          <p className="text-gray-600 mb-4">Puedes ponerte en contacto con nosotros a través de cualquiera de los siguientes medios:</p>
          <div className="flex items-center mb-4">
            <span className="text-warmBrown mr-4">
              <FaMapMarkerAlt size={24} />
            </span>
            <p>Barcelona</p>
          </div>
          <div className="flex items-center mb-4">
            <span className="text-warmBrown mr-4">
              <FaPhoneAlt size={24} />
            </span>
            <p className="text-gray-700">+34 623 91 82 83</p>
          </div>
          <div className="flex items-center mb-4">
            <span className="text-warmBrown mr-4">
              <FaEnvelope size={24} />
            </span>
            <p className="text-gray-700">proptusell@gmail.com</p>
          </div>
        </div>

        <form ref={form} id='contact' className="md:w-1/2 bg-cream p-8 shadow-lg rounded-2xl border border-gray-200" onSubmit={handleSubmit}>
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Envíanos un mensaje</h2>

          <label htmlFor="name" className="block text-gray-600 font-semibold mb-2">Nombre</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-xl bg-gray-100 focus:bg-white focus:ring-2 focus:ring-blue-400 transition-all duration-200 mb-4" 
            placeholder="Tu nombre"
            required 
          />

          <label htmlFor="email" className="block text-gray-600 font-semibold mb-2">Email</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-xl bg-gray-100 focus:bg-white focus:ring-2 focus:ring-blue-400 transition-all duration-200 mb-4" 
            placeholder="Tu email"
            required 
          />

          <label htmlFor="phone" className="block text-gray-600 font-semibold mb-2">Teléfono</label>
          <input 
            type="tel" 
            id="phone" 
            name="phone" 
            value={formData.phone}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-xl bg-gray-100 focus:bg-white focus:ring-2 focus:ring-blue-400 transition-all duration-200 mb-4" 
            placeholder="Tu teléfono"
          />

          <label htmlFor="message" className="block text-gray-600 font-semibold mb-2">Mensaje</label>
          <textarea 
            id="message" 
            name="message" 
            value={formData.message}
            onChange={handleChange}
            className="w-full p-3 border border-gray-300 rounded-xl bg-gray-100 focus:bg-white focus:ring-2 focus:ring-blue-400 transition-all duration-200 mb-6" 
            placeholder="Escribe tu mensaje"
            rows={5}
            required 
          ></textarea>

          <button 
            type="submit" 
            className="w-full bg-gradient-to-r from-cream to-warmBrown text-white py-3 rounded-xl shadow-md transition-all duration-200 transform hover:scale-105 hover:bg-warmBrown hover:from-warmBrown hover:to-warmBrown"
          >
            Enviar mensaje
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
