import React from 'react';

const Header: React.FC = () => {
  return (
    <header 
      className="relative h-screen bg-cover bg-center" 
      style={{ backgroundImage: "url('/header-background.jpg')" }}  // Aquí va tu imagen de fondo
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay para oscurecer un poco */}
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-white">
        <h1 className="text-5xl font-bold mb-4 animate-fade-in-down">Prop2Sell</h1>
        <p className="text-2xl mb-8 animate-fade-in">Encuentra tu hogar ideal</p>

        {/* Botón Call to Action */}
        <button 
          onClick={() => document.getElementById('contact')?.scrollIntoView({ behavior: 'smooth' })} 
          className="bg-warmBrown text-white py-3 px-6 rounded-lg mt-8 hover:bg-brown-600 transition-all duration-300 transform hover:scale-105 button-cta"        >
          Contáctanos
        </button>
      </div>
    </header>
  );
};

export default Header;
